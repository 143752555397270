// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper .landing-page__heading .cds--switcher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #c6c6c6;
    background-color: #161616;
}`, "",{"version":3,"sources":["webpack://./src/portal/components/HeaderContent/HeaderContent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".page-wrapper .landing-page__heading .cds--switcher {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    color: #c6c6c6;\n    background-color: #161616;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
