// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  text-decoration: "none";
}

.nav-link:link {
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-decoration: none;
}
.nav-link:visited {
  color: #fff;
}
.nav-link:hover {
  color: yellow;
}
.nav-link:active {
  color: teal;
}

.activeRoute {
  background-color: yellow;
  border-bottom: 0.4rem solid teal;
  cursor: not-allowed;
}

hr.divider {
  margin: 15px;
  color: whitesmoke
}`, "",{"version":3,"sources":["webpack://./src/portal/app/sidenav.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ;AACF","sourcesContent":[".nav-link {\n  text-decoration: \"none\";\n}\n\n.nav-link:link {\n  color: #fff;\n  font-size: 1.6rem;\n  line-height: 1.6rem;\n  text-decoration: none;\n}\n.nav-link:visited {\n  color: #fff;\n}\n.nav-link:hover {\n  color: yellow;\n}\n.nav-link:active {\n  color: teal;\n}\n\n.activeRoute {\n  background-color: yellow;\n  border-bottom: 0.4rem solid teal;\n  cursor: not-allowed;\n}\n\nhr.divider {\n  margin: 15px;\n  color: whitesmoke\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
