import { Content, HeaderContainer } from '@carbon/react';
import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HeaderContent from '../components/HeaderContent/HeaderContent';
const Home = React.lazy(() => import('./Home'));
const NotFound = React.lazy(() => import('./NotFound'));
const Catalogs = React.lazy(() => import('../pages/catalogs/Catalogs'));
const DataSources = React.lazy(() => import('../pages/datasources/DataSources'));
const Datatargets = React.lazy(() => import('../pages/datatargets/Datatargets'));
const History = React.lazy(() => import('../pages/history/History'));
const HistoryDetails = React.lazy(() => import('../pages/history/HistoryDetails'));
const HistoryUnresolved = React.lazy(() => import('../pages/history/HistoryUnresolved'));
const HistoryResolved = React.lazy(() => import('../pages/history/HistoryResolved'));
const HistoryUnresolvedDetails = React.lazy(() => import('../pages/history/HistoryUnresolvedDetails'));
const HistoryResolvedDetails = React.lazy(() => import('../pages/history/HistoryResolvedDetails'));
const Errors = React.lazy(() => import('../components/errors/Errors'));
const Investigator = React.lazy(() => import('../pages/investigator/Investigator'));
const middlewareApi = require('../hooks/utils/middlewareApi');
const PortalShell = () => {
    const [role, setRole] = React.useState();
    useEffect(() => {
        let mounted = true;
        middlewareApi.getLoggedInfo().then((info) => {
            if (mounted) {
                setRole(info['role']);
            }
        });
        return () => {
            mounted = false;
        };
    }, []);
    return (React.createElement(HeaderContainer, { render: ({ isSideNavExpanded, onClickSideNavExpand }) => (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "page-wrapper" },
                React.createElement(HeaderContent, { onClickSideNavExpand: onClickSideNavExpand, isSideNavExpanded: isSideNavExpanded, role: role }),
                React.createElement(Content, { id: "main-content" }, role ? (React.createElement(React.Fragment, null,
                    React.createElement(Suspense, { fallback: React.createElement("div", null, "Loading ...") },
                        React.createElement(Route, { exact: true, path: "/", render: () => React.createElement(Home, null) }),
                        React.createElement(Switch, null,
                            React.createElement(Redirect, { exact: true, from: "/home", to: "/catalogs" }),
                            React.createElement(Route, { path: "/catalogs", render: (props) => (React.createElement(Catalogs, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/data_sources", render: (props) => (React.createElement(DataSources, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/data_targets", render: (props) => (React.createElement(Datatargets, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/history", render: (props) => (React.createElement(History, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/history_catalog/catalogs/:catalog_name/data_targets/:datatarget_name/data_sources/:datasource_name", render: (props) => (React.createElement(History, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/history_details/:history_id", render: (props) => (React.createElement(HistoryDetails, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/unresolved", render: (props) => (React.createElement(HistoryUnresolved, Object.assign({}, props, { isAdmin: role == 'admin' || role == 'operator' ? true : false }))) }),
                            React.createElement(Route, { path: "/resolved", render: (props) => (React.createElement(HistoryResolved, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/unresolved_catalog/catalogs/:catalog_name/data_targets/:datatarget_name/data_sources/:datasource_name", render: (props) => (React.createElement(HistoryUnresolved, Object.assign({}, props, { isAdmin: role == 'admin' || role == 'operator' ? true : false }))) }),
                            React.createElement(Route, { path: "/resolved_catalog/catalogs/:catalog_name/data_targets/:datatarget_name/data_sources/:datasource_name", render: (props) => (React.createElement(HistoryResolved, Object.assign({}, props, { isAdmin: role == 'admin' ? true : false }))) }),
                            React.createElement(Route, { path: "/unresolved_details/:identifier/catalogs/:catalog_name/data_targets/:datatarget_name/data_sources/:datasource_name", render: () => React.createElement(HistoryUnresolvedDetails, null) }),
                            React.createElement(Route, { path: "/resolved_details/:identifier/catalogs/:catalog_name/data_targets/:datatarget_name/data_sources/:datasource_name", render: () => React.createElement(HistoryResolvedDetails, null) }),
                            role === 'operator' && (React.createElement(React.Fragment, null,
                                React.createElement(Route, { path: "/investigator", render: () => React.createElement(Investigator, null) }))),
                            role === 'admin' && (React.createElement(React.Fragment, null,
                                React.createElement(Route, { path: "/investigator", render: () => React.createElement(Investigator, null) }))),
                            React.createElement(Route, { path: "/error", component: Errors }),
                            React.createElement(Route, { path: "*", render: () => React.createElement(NotFound, null) }))))) : null)))) }));
};
export default PortalShell;
