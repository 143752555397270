import './sidenav.css';
import { SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import { Activity, Catalog, Checkmark, Datastore, Error, Exit, SearchAdvanced } from '@carbon/react/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
const Sidenav = ({ role, isSideNavExpanded }) => {
    const investigatorAccess = role === 'admin' || role === 'operator';
    return (React.createElement(SideNav, { isChildOfHeader: true, isPersistent: true, isRail: true, "aria-label": "Side navigation", expanded: isSideNavExpanded },
        React.createElement(SideNavItems, null,
            React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/catalogs" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(Catalog, { size: 20 }) }, "Catalogs")),
            React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/data_sources" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(Datastore, { size: 20 }) }, "Data Sources")),
            React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/data_targets" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(Exit, { size: 20 }) }, "Data Targets")),
            React.createElement("hr", { className: "divider" }),
            React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/history" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(Activity, { size: 20 }) }, "Activity")),
            React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/unresolved" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(Error, { size: 20 }) }, "Unresolved")),
            React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/resolved" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(Checkmark, { size: 20 }) }, "Resolved")),
            investigatorAccess && (React.createElement(NavLink, { className: "nav-link", activeClassName: "activeRoute", to: "/investigator" },
                React.createElement(SideNavLink, { renderIcon: () => React.createElement(SearchAdvanced, { size: 20 }) }, "Investigator"))))));
};
export default Sidenav;
