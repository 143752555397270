import './HeaderContent.css';
import { Header, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, SkipToContent } from '@carbon/react';
import { UserAvatar } from '@carbon/react/icons';
import React, { useState } from 'react';
import Sidenav from '../../app/Sidenav';
import UserActions from '../../app/UserActions';
const HeaderContent = ({ onClickSideNavExpand, isSideNavExpanded, role }) => {
    const [userMenuExpanded, setUserMenuExpanded] = useState(false);
    return (React.createElement(Header, { "aria-label": "Intel Data Management", className: "landing-page__heading" },
        React.createElement(SkipToContent, null),
        React.createElement(HeaderMenuButton, { className: "bx--header__menu-toggle", "aria-label": "Open menu", onClick: onClickSideNavExpand, isActive: isSideNavExpanded, isCollapsible: true }),
        React.createElement(HeaderName, { href: "/catalogs", prefix: "Intel" }, "Data Management"),
        React.createElement(HeaderGlobalBar, null,
            React.createElement(HeaderGlobalAction, { "aria-label": "User", onClick: () => setUserMenuExpanded(!userMenuExpanded) },
                React.createElement(UserAvatar, { size: 20 }))),
        React.createElement(UserActions, { "aria-label": "User actions", visible: userMenuExpanded }),
        React.createElement(Sidenav, { "aria-label": "Side navigation", role: role, isSideNavExpanded: isSideNavExpanded })));
};
export default HeaderContent;
