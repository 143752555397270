var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const ui_url = window.location.protocol + '//' + window.location.host;
const server_api_url_path = ui_url + '/auth/server_api_url';
const token_url = ui_url + '/auth/server_access_token';
const logged_url = ui_url + '/auth/logged';
let server_api_url;
const getServerAPIURL = () => __awaiter(this, void 0, void 0, function* () {
    if (!server_api_url) {
        const res = yield fetch(`${server_api_url_path}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const jsonResponse = yield res.json();
        server_api_url = jsonResponse['server_api_url'];
    }
    return server_api_url;
});
const getAuthorizationToken = () => __awaiter(this, void 0, void 0, function* () {
    const res = yield fetch(`${token_url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const jsonResponse = yield res.json();
    const token = jsonResponse['accessToken'];
    const auth = `'Bearer ${token}'`;
    return auth.replace(/'/g, '');
});
const getLoggedInfo = () => __awaiter(this, void 0, void 0, function* () {
    const res = yield fetch(`${logged_url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const jsonResponse = yield res.json();
    const loggedInAs = jsonResponse['loggedInAs'];
    return loggedInAs;
});
module.exports = { getServerAPIURL, getAuthorizationToken, getLoggedInfo };
