import './useractions.css';
import { HeaderPanel, Switcher } from '@carbon/react';
import React from 'react';
const middlewareApi = require('../hooks/utils/middlewareApi');
/**
 * Component that shows the user specific actions like info and logout.
 * Renders at the top right of the page.
 */
const UserActions = (props) => {
    const [user, setUser] = React.useState();
    React.useEffect(() => {
        let mounted = true;
        middlewareApi.getLoggedInfo().then((info) => {
            if (mounted) {
                setUser(info['name']);
            }
        });
        return () => {
            mounted = false;
        };
    }, [props]);
    return (React.createElement(HeaderPanel, { "aria-label": "Profile Menu", expanded: props.visible },
        React.createElement(Switcher, { "aria-label": "Switcher Container" },
            React.createElement("div", { className: "userInfoContainer" },
                React.createElement("span", null,
                    user,
                    " ")))));
};
export default UserActions;
