// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cds--header-panel {
  max-height: 48pt
}

.userInfoContainer {
  width: 88%;
  margin: 1.5rem;
  font-size: medium;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/portal/app/useractions.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".cds--header-panel {\n  max-height: 48pt\n}\n\n.userInfoContainer {\n  width: 88%;\n  margin: 1.5rem;\n  font-size: medium;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
